import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useIntl } from 'gatsby-plugin-intl'

import Buttons, { Button } from '../../components/Buttons'
import Container from '../../components/Container'
import { useTheme } from '../../styles/theme'

import {
  Wrapper,
  CallToAction,
  Title,
  Image,
  decorationStyles1,
  decorationStyles2,
} from './styles'


export default () => {
  const images = useStaticQuery(graphql`
    query Container {
      robot: imageSharp(fluid: { originalName: { eq: "robot-error.png" } }) {
        fluid(quality: 92, maxWidth: 648) {
          presentationWidth
          ...GatsbyImageSharpFluid_tracedSVG
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      deco1: imageSharp(fixed: { originalName: { eq: "deco0.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco2: imageSharp(fixed: { originalName: { eq: "deco1.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  `)
  const theme = useTheme();
  const decorative = useMediaQuery(
    `(min-width: ${theme.breakpoints.xxl + 120}px)`
  )
  const { formatMessage } = useIntl()

  return (
    <Wrapper>
      <Container>
        <Title center>{formatMessage({ id: 'error-page.title' })}</Title>
        <Image fluid={images.robot.fluid} />
        <CallToAction dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'error-page.outro' }) }} />
        <Buttons>
          <Button variant="outlined" hash="/">{formatMessage({ id: 'buttons.go-back' })}</Button>
          <Button hash="contacts">{formatMessage({ id: 'buttons.contact' })}</Button>
        </Buttons>
        {decorative && (
          <>
            <Image fixed={images.deco1.fixed} css={decorationStyles1} />
            <Image fixed={images.deco2.fixed} css={decorationStyles2} />
          </>
        )}
      </Container>
    </Wrapper>
  )
}
