import ImageBase from 'gatsby-image'
import { css } from '@emotion/core'

import styled from '../../styles/theme'
import TitleBase from '../../components/Title'
import CallToActionBase from '../../components/CallToAction'
import { Wrapper as WrapperBase } from '../../components/Container'


export const Wrapper = styled(WrapperBase)`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.light};
    background: ${theme.gradients[1]};
    padding-bottom: ${theme.spacing.base * 7}px;
  `}
`

export const CallToAction = styled(CallToActionBase)`
  ${({ theme }) => `
    font-size: ${theme.fonts.sizes.regular};

    @media (min-width: ${theme.breakpoints.lg}px) {
      margin-top: ${theme.spacing.base * 2}px;
      margin-bottom: ${theme.spacing.base * 4}px;
    }
  `}
`

export const Title = styled(TitleBase)`
  ${({ theme }) => `
    max-width: 1000px;
    font-size: 44px;
    color: ${theme.palette.text.light};
  `}
`

export const Image = styled(ImageBase)`
  margin-left: auto;
  margin-right: auto;
`

export const decorationStyles1 = css`
  position: absolute !important;
  left: -48px;
  bottom: 140px;
`

export const decorationStyles2 = css`
  position: absolute !important;
  right: -52px;
  top: 200px;
`
