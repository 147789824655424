import React from 'react'

import IndexLayout from '../layouts'
import Error from '../containers/Error'
import Menu from '../containers/Menu'
import Footer from '../containers/Footer'


export default ({ pathContext: { intl: { originalPath } } }) => (
  <IndexLayout>
    <Menu originalPath={originalPath} />
    <Error />
    <Footer originalPath={originalPath} />
  </IndexLayout>
)
